:root {
  --lh-10: 1.3;
  --mb-10: 1.8em;
  --fs-50: 0.85rem; /*13.6px*/
  --fs-100: 1rem; /*16px*/
  --fs-150: 1.15rem; /*18.4px*/
  --fs-200: 1.1rem; /*20px*/
  --fs-300: 1.3rem; /*24px*/
  --fs-350: 1.45rem; /*24px*/
  --fs-400: 1.58rem; /*28px*/
  --fs-500: 3.25rem; /*32px*/
  --fs-550: 2.35rem; /*36px*/
  --fs-600: 2.15rem; /*40px*/
  --fs-700: 3rem; /*48px*/
  --fs-800: 4rem; /*64px*/
  --fs-900: clamp(4rem, 13.4vw + 1rem, 20rem); /*128px*/
  --ff-main: "Roboto Slab", serif;
  --clr-side-white: #fff;
  --clr-side-black: #222;
  --clr-primary-white: #fdfdfd;
  --clr-primary-black: #000;
  --clr-primary-orange: #ff8b68;
  --clr-primary-redish: #ff501c;
  --clr-primary-darkblue: #1a2456;
  --clr-primary-pink: #efc2b3;
}

@media (max-width: 40em) {
  :root {
    --lh-10: 1.2;
    --mb-10: 1.8em;
    --fs-50: 0.85rem; /*13.6px*/
    --fs-100: 1rem; /*16px*/
    --fs-150: 1.15rem; /*18.4px*/
    --fs-200: 1rem; /*19.2px*/
    --fs-300: 1.1rem; /*20px*/
    --fs-350: 1.5rem; /*24px*/
    --fs-400: 1.1rem; /*24px*/
    --fs-500: 2.2rem; /*28px*/
    --fs-550: 1.7rem; /*30.4px*/
    --fs-600: 1.485rem; /*33.6px*/
    --fs-700: 2.25rem; /*36px*/
    --fs-800: 2.43rem; /*48px*/
    --fs-900: clamp(2rem, 9.25vw + 1rem, 10rem);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

nav {
  width: 100%;
  z-index: 1000;
}

body {
  font-family: var(--ff-main);
  color: var(--clr-side-black);
  background-color: var(--clr-primary-white);
  font-weight: normal;
  line-height: var(--lh-10);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
  max-width: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.column {
  flex-direction: column;
}

.grid {
  display: grid;
}

.center {
  margin: 0 auto;
}

.center-text {
  text-align: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.text-hollow {
  color: inherit;
  text-shadow: 2px 2px black, -1px 1px black, 1px -1px black, -1px -1px black;
}

.btn {
  padding: 0.5em 1em;
  cursor: pointer;
  background-color: var(--clr-side-black);
  color: var(--clr-side-white);
}

/*============= 
    Typography 
===============*/

input[type="checkbox"] {
  display: none;
}

ul,
li,
a {
  text-decoration: none;
  list-style: none;
  color: var(--clr-primary-black);
}

.fs-50 {
  font-size: var(--fs-50);
} /*13.6px*/
.fs-100 {
  font-size: var(--fs-100);
} /*16px*/
.fs-150 {
  font-size: var(--fs-150);
} /*18.4px*/
.fs-200 {
  font-size: var(--fs-200);
} /*20px*/
.fs-300 {
  font-size: var(--fs-300);
} /*24px*/
.fs-350 {
  font-size: var(--fs-350);
} /*24px*/
.fs-400 {
  font-size: var(--fs-400);
} /*28px*/
.fs-500 {
  font-size: var(--fs-500);
} /*32px*/
.fs-550 {
  font-size: var(--fs-550);
} /*36px*/
.fs-600 {
  font-size: var(--fs-600);
} /*40px*/
.fs-700 {
  font-size: var(--fs-700);
} /*48px*/
.fs-800 {
  font-size: var(--fs-800);
} /*64px*/
.fs-900 {
  font-size: var(--fs-900);
} /*128px*/

/*============= 
    Body
===============*/

.hero-container {
  width: 100%;
  background-color: var(--clr-primary-black);
}

.hero {
  height: 100vh;
  margin: 0 auto;
  width: 90%;
  justify-content: center;
  align-items: center;
}

.hero-text {
  background-image: radial-gradient(
    111.21% 111.21% at 45.07% 39.66%,
    #ff0624 0%,
    rgba(115, 0, 255, 0.4) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-repeat: repeat;
  overflow-wrap: break-word;
  letter-spacing: 3px;
  user-select: none;
}

.section-container {
  margin: 0 auto;
  width: min(90%, 57rem);
}

.section-margin {
  margin-bottom: 8rem;
}

.about-container {
  gap: 1rem;
  justify-items: center;
  text-align: justify;
}

.foto {
  max-width: 25rem;
  padding: 0.35rem;
  border-radius: 15px;
}

.foto * {
  border: 7px solid #fefefe;
  border-radius: 15px;
  box-shadow: 3px 3px 5px #35353533, -1px -1px 2px #35353525;
}

.about-bg {
  color: white;
  border-radius: 15px;
  font-weight: 600;
  padding: 1.5rem;
  box-shadow: 4px 4px 5px #3535355e, -1px -1px 2px #35353525;
  background-color: #21d4fd;
  background-image: linear-gradient(225deg, #21d4fd 0%, #b721ff 100%);
}

.about-text {
  font-weight: 500;
  padding: 1rem 1rem 0;
}

.work-title {
  margin: 0 auto;
  width: min(90%, 57rem);
}

.grid-foto {
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid black;
  max-width: 128rem;
  margin: 0 auto;
}

.grid-foto > div {
  padding-top: 100%;
  position: relative;
}

.grid-foto > div > div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border: 1px solid black;
  overflow: hidden;
}

.grid-foto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: yellowgreen;
}

.show-all {
  place-items: center;
  background-color: rgb(15, 15, 15);
}

.show-all a {
  background-color: #21d4fd;
  background-image: linear-gradient(242deg, #21d4fd 0%, #b721ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-size: 100%;
  background-repeat: repeat;
}

.show-all a:hover {
  transform: scale(1.01);
  transition: 0.3s ease-out;
}

.contacts-container {
  padding: 2rem 0;
}

.contacts {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 3rem;
}

.contacts a {
  font-size: 1.4rem;
  font-weight: 500;
  text-shadow: 1px 1px 2px #1f1f1fa9;
}

.contacts a:hover {
  transform: scale(1.005);
  transition: 0.3s ease-out;
}

.text-shadow {
  text-shadow: 1px 1px 2px #1f1f1f7e;
}

.bottom {
  margin-bottom: var(--mb-10);
}

.box-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/*============= 
    Footer 
===============*/

.footer-container {
  margin: 0 auto;
  width: 100%;
  padding: 1.15rem 0;
  justify-content: center;
  align-items: center;
  background-color: rgb(168, 167, 167);
}

.none {
  display: none;
}

.logo-small {
  width: 2.5rem;
}

.footer-text {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

/*============= 
   Media Queries 
===============*/

@media (min-width: 40em) {
  .hero {
    text-align: center;
  }

  .section-margin {
    margin-bottom: 10rem;
  }

  .about-container {
    gap: 0;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(2, 13rem);
  }

  .foto {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    padding: 0.5rem;
  }

  .about-bg {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    padding: 1rem 4rem 0 18rem;
  }

  .about-text {
    grid-column: 4 / -1;
    grid-row: 2 / 3;
    padding: 1rem 3rem 0 1rem;
  }

  .grid-foto {
    grid-template-columns: repeat(4, 1fr);
  }

  .contacts a {
    font-size: 1.65rem;
  }

  .contacts-container {
    padding: 5rem 0;
  }

  .box {
    z-index: 100;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0.75rem;
    background-color: #21d5fd2f;
    background-image: linear-gradient(242deg, #21d5fd75 0%, #b921ff75 100%);
    transition: 0.5s ease-out;
  }

  .zoom {
    transition: 0.5s ease-out;
    transform: scale(1.1);
  }

  .unzoom {
    transition: 0.5s ease-out;
    transform: scale(1);
  }

  .project {
    font-weight: 600;
    text-shadow: 2px 2px 2px #0c0c0ca9;
    color: rgb(170, 255, 0);
    user-select: none;
  }

  .project:hover {
    transform: scale(1.005);
    transition: 0.3s ease-out;
  }

  .project-code {
    letter-spacing: 1px;
    font-weight: 500;
    background-color: rgba(128, 128, 128, 0.1);
    text-shadow: 2px 2px 2px #0c0c0ca9;
    color: rgb(255, 255, 255);
    user-select: none;
  }

  .project-code:hover {
    transform: scale(1.005);
    transition: 0.3s ease-out;
  }
}

@media (max-width: 62.5em) {
  .hero {
    text-align: left;
  }

  .mobile-none {
    display: none;
  }
}

@media (max-width: 40em) {
  .hero-text {
    padding-bottom: 2.5em;
  }
}
